<template>
  <div class="inner-layout">
    <div class="layout-filter-box">
      <filters v-if="opt" :opt="opt" :obj="{ cateCode: this.cateCode, petCode: this.petCode }" @set="setFilters" />

      <mini-filter :opt="opt.cateCode" :target="cateCode" :targetTitle="'cateCode'" :title="'카테고리'" :inputType="'radio'" @set="setMiniFilter" />

      <mini-filter :opt="opt.petCode" :target="petCode" :targetTitle="'petCode'" :title="'펫 구분'" :inputType="'checkbox'" @set="setMiniFilter" />
    </div>

    <div class="sort-box">
      <div v-for="(item, index) in opt.sort" :key="`sort${index}`" :class="{'on' : item.value === sort}" @click="setSort(item.value)">{{ item.text }}</div>
    </div>

    <div class="item-container column-4" v-if="list && list.length > 0">
      <card v-for="(item, index) in list" :key="`tip-${item.boIdx}-${index}`" :item="item" cardType="default" @click="goDetail(item.boIdx)"></card>
    </div>
    <div v-else class="noDataMessage" style="border: 0">{{ $msg('nodata2') }}</div>

    <infinite-loading ref="infiniteLoading" spinner="spiral" :identifier="identifier" @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>
<script>
import listMixin from "@/mixins/listMixin"
export default {
  name: "tipList",
  mixins: [listMixin],
  data() {
    return {
      parentCategory: 10,
      cateCode: 10,
    }
  },
  created() {
    this.setOptCateCode()
    if (localStorage.getItem('tipFilter')) {
      const filter = JSON.parse(localStorage.getItem('tipFilter'))
      this.cateCode = filter.cateCode
      this.petCode = filter.petCode.length > 0 ? filter.petCode.split(',') : []
    }
  },
  methods: {
    async setOptCateCode() {
      const { result, data } = await this.$api.getCtgry({ cate: this.parentCategory });
      if (result === 'success' && data.length > 0) {
        this.opt.cateCode = data.reduce((list, d) => {
          d.text = d.categoryName
          d.value = d.cateCode
          list.push(d)
          return list
        }, [])
        this.opt.cateCode.unshift({ text: '전체', value: this.parentCategory },)
      }
    },
    setFilters(obj) {
      this.cateCode = obj.cateCode
      this.petCode = obj.petCode
      this.setLocalStorage()
    },
    setMiniFilter(target, value) {
      this[target] = value
      this.setLocalStorage()
    },
    goDetail(id) {
      this.setBeforeContent()
      this.$router.push(`/tip/${id}`)
    },
    setLocalStorage() {
      localStorage.setItem('tipFilter', JSON.stringify({
        cateCode: this.cateCode,
        petCode: this.petCode.join(','),
      }))
    }
  }
}
</script>
